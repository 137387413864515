import React from 'react';
import './About.css';

const About = () => {
  const skills = [
    "Python", "Java", "C++", "JavaScript", "React",
    "Node.js", "Docker", "Kubernetes", "AWS",
    "Machine Learning", "Data Science", "CI/CD",
    "Git", "REST APIs", "Microservices"
  ];

  return (
    <section className="about">
      <div className="about-content">
        <h1>Hello, I'm Vivek</h1>
        <div className="about-text">
          <p>I am a passionate Software Engineer with extensive experience in full-stack development, cloud technologies, and machine learning. Currently based in Foster City, CA, I specialize in building scalable applications and implementing efficient CI/CD pipelines.</p>
          <p>With a strong foundation in computer science and years of industry experience, I've worked on diverse projects ranging from web applications to machine learning systems, always focusing on delivering high-quality, maintainable code.</p>
        </div>
        <div className="skills">
          <h2>Technical Skills</h2>
          <div className="skills-grid">
            {skills.map((skill, index) => (
              <span key={index} className="skill-tag">{skill}</span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About; 