// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  min-height: 100vh;
  padding: 6rem 2rem 2rem;
  background-color: #121212;
  color: #ffffff;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
}

.about h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, #007bff, #00ff88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.skills {
  margin-top: 3rem;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.skill-tag {
  background-color: #1e1e1e;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: 1px solid #007bff;
} `, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,oDAAoD;EACpD,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".about {\n  min-height: 100vh;\n  padding: 6rem 2rem 2rem;\n  background-color: #121212;\n  color: #ffffff;\n}\n\n.about-content {\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n.about h1 {\n  font-size: 3rem;\n  margin-bottom: 2rem;\n  background: linear-gradient(45deg, #007bff, #00ff88);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.about-text {\n  font-size: 1.2rem;\n  line-height: 1.6;\n  margin-bottom: 2rem;\n}\n\n.skills {\n  margin-top: 3rem;\n}\n\n.skills-grid {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n  margin-top: 1rem;\n}\n\n.skill-tag {\n  background-color: #1e1e1e;\n  padding: 0.5rem 1rem;\n  border-radius: 20px;\n  border: 1px solid #007bff;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
