// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  min-height: 100vh;
  padding: 6rem 2rem 2rem;
  background-color: #121212;
  color: #ffffff;
}

.contact h1 {
  text-align: center;
  margin-bottom: 3rem;
}

.contact-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: #1e1e1e;
  padding: 2rem;
  border-radius: 10px;
}

.social-links {
  margin: 2rem 0;
}

.social-links a {
  color: #007bff;
  margin-right: 1rem;
  text-decoration: none;
}

.download-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
} `, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".contact {\n  min-height: 100vh;\n  padding: 6rem 2rem 2rem;\n  background-color: #121212;\n  color: #ffffff;\n}\n\n.contact h1 {\n  text-align: center;\n  margin-bottom: 3rem;\n}\n\n.contact-content {\n  max-width: 800px;\n  margin: 0 auto;\n  background-color: #1e1e1e;\n  padding: 2rem;\n  border-radius: 10px;\n}\n\n.social-links {\n  margin: 2rem 0;\n}\n\n.social-links a {\n  color: #007bff;\n  margin-right: 1rem;\n  text-decoration: none;\n}\n\n.download-button {\n  display: inline-block;\n  background-color: #007bff;\n  color: white;\n  padding: 0.8rem 1.5rem;\n  border-radius: 5px;\n  text-decoration: none;\n  transition: background-color 0.3s ease;\n}\n\n.download-button:hover {\n  background-color: #0056b3;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
