import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section className="contact">
      <h1>Get In Touch</h1>
      <div className="contact-content">
        <div className="contact-info">
          <h2>Contact Information</h2>
          <p>Email: vivekjchacko@gmail.com</p>
          <p>Location: Foster City, CA</p>
          <div className="social-links">
            <a href="https://linkedin.com/in/vivekchacko" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            <a href="https://github.com/vivekjc" target="_blank" rel="noopener noreferrer">GitHub</a>
          </div>
          <div className="resume-section">
            <h2>Resume</h2>
            <a href="/assets/documents/resume.pdf" download className="download-button">
              Download Resume
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact; 