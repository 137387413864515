import React, { useState, useEffect } from 'react';
import './Projects.css';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://api.github.com/users/vivekjc/repos');
        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }
        const data = await response.json();
        
        // Sort projects by stars and last updated date
        const sortedProjects = data
          .filter(repo => !repo.fork) // Filter out forked repositories
          .sort((a, b) => {
            // Sort by stars first, then by last updated date
            if (b.stargazers_count !== a.stargazers_count) {
              return b.stargazers_count - a.stargazers_count;
            }
            return new Date(b.updated_at) - new Date(a.updated_at);
          });

        setProjects(sortedProjects);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return (
      <section className="projects">
        <h1>My Projects</h1>
        <div className="loading">Loading projects...</div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="projects">
        <h1>My Projects</h1>
        <div className="error">Error: {error}</div>
      </section>
    );
  }

  return (
    <section className="projects">
      <h1>My Projects</h1>
      <div className="projects-grid">
        {projects.map((project) => (
          <div className="project-card" key={project.id}>
            <h3>{project.name}</h3>
            <p>{project.description || 'No description available'}</p>
            <div className="project-stats">
              <span>⭐ {project.stargazers_count}</span>
              <span>🔄 {project.forks_count}</span>
            </div>
            <div className="project-tech">
              <span className="tech-tag">{project.language}</span>
            </div>
            <div className="project-links">
              <a href={project.html_url} target="_blank" rel="noopener noreferrer" className="github-link">
                View on GitHub
              </a>
              {project.homepage && (
                <a href={project.homepage} target="_blank" rel="noopener noreferrer" className="demo-link">
                  Live Demo
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects; 