import React from 'react';
import './YouTube.css';

const YouTube = () => {
  const videos = [
    {
      id: '9LYEsAidEeE',
      title: 'Drone Flight - Aerial View',
      url: 'https://youtu.be/9LYEsAidEeE?si=xFHWb9-5z3s0MNbp'
    },
    {
      id: 'Ldc1eo2QAYw',
      title: 'Drone Flight - Scenic Views',
      url: 'https://youtu.be/Ldc1eo2QAYw?si=Y_BvbeJPrirmKGEm'
    }
  ];

  return (
    <section className="youtube">
      <h1>Drone Flying Adventures</h1>
      <div className="youtube-content">
        <div className="channel-info">
          <h2>My Drone Channel</h2>
          <p>Join me on my aerial adventures as I explore beautiful landscapes and capture stunning views from above.</p>
          <a 
            href="https://www.youtube.com/@codered378" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="subscribe-button"
          >
            <span>📺</span> Visit Channel
          </a>
        </div>
        <div className="videos-grid">
          {videos.map((video) => (
            <div key={video.id} className="video-container">
              <iframe
                src={`https://www.youtube.com/embed/${video.id}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default YouTube; 