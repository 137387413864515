// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
}

.App::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    #000000,
    #1a1a1a,
    #000033,
    #1a0033
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  z-index: -1;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Update section backgrounds to be semi-transparent */
.about,
.projects,
.experience,
.contact {
  background-color: rgba(18, 18, 18, 0.7) !important;
}

/* Add a subtle glow effect to headings */
h1, h2, h3 {
  text-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
}

/* Add glass effect to cards */
.project-card,
.timeline-content,
.contact-content {
  background: rgba(30, 30, 30, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ;;;;;;GAMC;EACD,0BAA0B;EAC1B,qCAAqC;EACrC,WAAW;AACb;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA,sDAAsD;AACtD;;;;EAIE,kDAAkD;AACpD;;AAEA,yCAAyC;AACzC;EACE,4CAA4C;AAC9C;;AAEA,8BAA8B;AAC9B;;;EAGE,iCAAiC;EACjC,2BAA2B;EAC3B,0CAA0C;EAC1C,4CAA4C;AAC9C;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".App {\n  text-align: center;\n  min-height: 100vh;\n  position: relative;\n}\n\n.App::before {\n  content: '';\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(\n    45deg,\n    #000000,\n    #1a1a1a,\n    #000033,\n    #1a0033\n  );\n  background-size: 400% 400%;\n  animation: gradient 15s ease infinite;\n  z-index: -1;\n}\n\n@keyframes gradient {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n\n/* Update section backgrounds to be semi-transparent */\n.about,\n.projects,\n.experience,\n.contact {\n  background-color: rgba(18, 18, 18, 0.7) !important;\n}\n\n/* Add a subtle glow effect to headings */\nh1, h2, h3 {\n  text-shadow: 0 0 10px rgba(0, 123, 255, 0.3);\n}\n\n/* Add glass effect to cards */\n.project-card,\n.timeline-content,\n.contact-content {\n  background: rgba(30, 30, 30, 0.8);\n  backdrop-filter: blur(10px);\n  border: 1px solid rgba(255, 255, 255, 0.1);\n  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);\n}\n\n.App-header {\n  background-color: #000000;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
