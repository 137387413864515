import React from 'react';
import './Research.css';

const Research = () => {
  const researchProjects = [
    {
      title: "Phoenix CubeSat - NASA/ASU Space Grant",
      institution: "Arizona State University (NASA-funded)",
      duration: "Dec 2018 – May 2020",
      description: [
        "Developed low-packet-loss image downlink algorithms in C for space communication",
        "Worked on RTOS shared libraries to enable CubeSat subsystem interoperability",
        "Personally delivered the CubeSat to Houston, featured in media coverage"
      ],
      link: "http://phxcubesat.asu.edu/"
    },
    {
      title: "Technology Opportunity Discovery Using NLP",
      institution: "Arizona State University",
      duration: "Jan 2020 – May 2020",
      description: [
        "Implemented Naïve Bayes and word2vec/BERT for AI-driven research insights"
      ]
    },
    {
      title: "AI Reinforcement Learning for OpenAI Gym",
      institution: "Arizona State University",
      duration: "Jan 2019 – Apr 2019",
      description: [
        "Developed Deep Q-Network (DQN) models, achieving 3x human performance"
      ]
    }
  ];

  return (
    <section className="research">
      <h1>Research Projects</h1>
      <div className="research-grid">
        {researchProjects.map((project, index) => (
          <div className="research-card" key={index}>
            <h3>{project.title}</h3>
            <p className="institution">{project.institution}</p>
            <p className="duration">{project.duration}</p>
            <ul>
              {project.description.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
            {project.link && (
              <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">
                View Project Website
              </a>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Research; 