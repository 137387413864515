import React from 'react';
import './Experience.css';

const Experience = () => {
  const experiences = [
    {
      company: "Meta Platforms Inc.",
      position: "Software Engineer (L5)",
      duration: "Mar 2024 - Present",
      description: [
        "Leading system design and architecture efforts for secure business identity management at scale.",
        "Enhancing security protocols and ensuring compliance with GDPR, HIPAA, and COPPA to streamline business customer onboarding.",
        "Coordinating and implementing automated testing strategies for Platform SDK, improving reliability for over 200k MAU."
      ]
    },
    {
      company: "Amazon.com, Inc.",
      position: "Software Development Engineer II",
      duration: "Oct 2020 - Mar 2024",
      description: [
        "Led integration of an external vendor-based ID verification system, reducing operational load by 40% and accelerating seller enrollments.",
        "🏆 Awarded 'Deliver Results' (Q3 2023) for impactful execution in reducing ops load and improving seller verification.",
        "Designed and implemented backend APIs & full-stack solutions (Java, Spring, ReactJS) to enhance brand enrollment for 100k+ customers.",
        "Led multiple design reviews and secured stakeholder buy-in for major technical initiatives.",
        "🏆 Brand Registry Hackathon (2nd place) winner for innovative automation in fraud detection.",
        "Mentored summer interns and conducted knowledge-sharing sessions."
      ]
    },
    {
      company: "Wollborg Michelson Personnel Service",
      position: "Software Development Lead/Architect",
      duration: "July 2020 - Oct 2020",
      description: [
        "Designed and implemented a data pipeline for DoorDash’s Safety & Security team, automating reporting workflows for real-time decision-making.",
        "Coordinated with stakeholders to revise data capture methods and optimize data architecture."
      ]
    },
    {
      company: "Phoenix CubeSat, NASA, Arizona State University",
      position: "Software Engineer",
      duration: "Dec 2018 - May 2020",
      description: [
        "Engineered, tested, and delivered ASU’s first CubeSat to study the Urban Heat Island effect across seven US cities.",
        "Designed algorithms that enable reliable image downlink with <1% packet loss using C and CRC32 checksum.",
        "Personally delivered the CubeSat to Nanoracks in Houston, TX, and was featured in the news for project contributions."
      ]
    },
    {
      company: "TATA Consultancy Services (TCS)",
      position: "Software Developer/Engineer",
      duration: "Sep 2011 - July 2018",
      description: [
        "Developed and optimized enterprise applications using JavaScript, Python, and SQL, improving system scalability by 30%.",
        "Automated end-to-end data pipelines, enabling real-time analytics and cutting manual reporting costs.",
        "Led training sessions for 200+ business users at Saudi Telecom and Telekom Malaysia.",
        "🏆 Awarded 'Star Performer' for improving analytics runtime speeds by 20x using optimized materialized views."
      ]
    }
  ];

  const education = [
    {
      institution: "Arizona State University",
      degree: "Master of Science (MS) - Information Technology",
      duration: "Aug 2018 - May 2020",
      achievement: "GPA: 4.0/4.0"
    },
    {
      institution: "KIIT University",
      degree: "Bachelor of Technology (B.Tech) - Computer Science",
      duration: "Aug 2007 - July 2011",
      achievement: "CGPA: 7.4/10"
    }
  ];

  return (
    <section className="experience">
      <h1>Professional Journey</h1>

      <h2>Work Experience</h2>
      <div className="timeline">
        {experiences.map((exp, index) => (
          <div className="timeline-item" key={index}>
            <div className="timeline-content">
              <h3>{exp.position}</h3>
              <h4>{exp.company}</h4>
              <p className="duration">{exp.duration}</p>
              <ul className="experience-list">
                {exp.description.map((point, i) => (
                  <li key={i}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <h2>Education</h2>
      <div className="timeline">
        {education.map((edu, index) => (
          <div className="timeline-item" key={index}>
            <div className="timeline-content">
              <h3>{edu.degree}</h3>
              <h4>{edu.institution}</h4>
              <p className="duration">{edu.duration}</p>
              <p className="achievement">{edu.achievement}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience; 