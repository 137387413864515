import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import profileImage from '../../assets/images/profile.jpg';

const Navbar = () => {
  const location = useLocation();

  const isActive = (path) => {
    if (path === '/' && location.pathname === '/') return true;
    if (path !== '/' && location.pathname.startsWith(path)) return true;
    return false;
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src={profileImage} alt="Vivek J Chacko" className="profile-logo" />
        <Link to="/">Vivek J Chacko</Link>
      </div>
      <div className="navbar-links">
        <Link to="/" className={isActive('/') ? 'active' : ''}>About</Link>
        <Link to="/projects" className={isActive('/projects') ? 'active' : ''}>Projects</Link>
        <Link to="/research" className={isActive('/research') ? 'active' : ''}>Research</Link>
        <Link to="/youtube" className={isActive('/youtube') ? 'active' : ''}>YouTube</Link>
        <Link to="/experience" className={isActive('/experience') ? 'active' : ''}>Experience</Link>
        <Link to="/contact" className={isActive('/contact') ? 'active' : ''}>Contact</Link>
      </div>
    </nav>
  );
};

export default Navbar; 